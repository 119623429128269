/* General Styles */
.future-head {
  text-align: left;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  font-family: Times, sans-serif;
}

.underline {
  border-bottom: 2px solid #D9A04C;
  width: 100px;
  margin-top: 10px;
}

.product-slider {
  margin-top: -25px;
  position: relative;
  overflow: hidden;
}

.product-slide {
  display: flex;
  margin-top: 25px;
}

.product {
  text-align: center;
  margin-right: 20px;
}

.product-image {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  padding: 5px;
}

.product-name {
  margin-top: 10px;
  font-family: 'Baskerville';
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.prev {
  left: 1px;
}

.next {
  right: 1px;
}

.products-container {
  padding-left: 10px;
  padding-right: 10px;
}

/* Parallax Effect Styles */
.parallax-container {
  position: relative;
  height: 40vh; /* Adjust height as needed */
  overflow: hidden;
}

.parallax-image {
  background-image: url('https://jewelkin-codezeel.myshopify.com/cdn/shop/files/parallax-img.jpg?v=1668516125');
  background-size: cover; /* Cover the entire container */
  background-position: center; /* Center the background */
  height: 100%; /* Make the image fill the entire container */
  width: 100%; /* Make the image fill the entire container */
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; /* Place the image behind other content */
  background-attachment: scroll; /* Remove fixed attachment for better mobile compatibility */
  animation: parallax 10s linear infinite;
}

@keyframes parallax {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 100%;
  }
}

/* Banner Title Styles */
.cms-banner-title-three {
  font-size: 35px;
  font-weight: 300;
  color: var(--color-base-accent-1);
  letter-spacing: 0;
  text-align: left;
  margin-top: 50px;
}

/* Container Adjustments */
.container.after-TopCategories {
  display: flex;
  justify-content: space-between;
}

.image-container {
  position: relative;
  flex: 1;
  margin-bottom: 20px; /* Add space between image containers */
  overflow: hidden; /* Hide overflowing text */
}

.text-overlay {
  position: absolute;
  top: 15px;
  right: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  color: black;
  padding: 20px; /* Add padding */
  box-sizing: border-box; /* Include padding in width calculation */
  text-align: center; /* Center text */
}

.left-image,
.right-image {
  width: 95%;
}

.left-text,
.right-text {
  width: 30%; /* Set text width to half of image width */
  box-sizing: border-box; /* Include padding in width calculation */
  padding: 10px; /* Add padding */
}

.shopNow2,
.shopNow3 {
  margin-top: 20%;
  height: 50px;
  width: 130px;
  font-size: 18px;
  background-color: none;
}

/* Responsive Adjustments */
@media (max-width: 767px) {
  .container.after-TopCategories {
    flex-direction: column;
  }

  .image-container {
    margin-bottom: 0; /* Remove space between image containers */
    padding: 10px;
  }

  .text-overlay {
    position: absolute;
    top: 1px;
    right: 0;
    bottom: 0;
    left: 70%; /* Position the text overlay at the middle of the image */
    transform: translateX(-50%); /* Adjust transform to center text horizontally */
    color: black;
    padding: 20px; /* Add padding */
    box-sizing: border-box; /* Include padding in width calculation */
    text-align: left; /* Left align text */
  }

  .shopNow2,
  .shopNow3 {
    height: 30px;
    width: 90px;
    font-size: 15px;
    border: none;
  }

  .shopNow-Head {
    font-size: 18px;
  }

  .head-rings{
    padding-top: 0.5rem !important;
  }
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.future-head {
  margin: 0; /* Reset default margin */
}

.tsp-button {
  height: 37px;
  width: 125px;
  padding: 7px 5px;
  background-color: #D9A04C;
  color: white;
  border: none;
}

.tsp-button-par {
  height: 37px;
  width: 125px;
  padding: 7px 5px;
  background-color: #D9A04C;
  color: white;
  border: none;
}


  
  