.container-Location{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
  }
  
  .map-container {
    flex: 1;
    max-width: 30%; /* Control the size of the map */
  }
  
  .info-container {
    flex: 2;
    padding-left: 20px; /* Reduce padding for better spacing */
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
      align-items: center;
    }
  
    .map-container {
      min-width: 100%; /* Full width on smaller screens */
      margin-bottom: 20px; /* Space between map and info */
    }
  
    .info-container {
      padding-left: 0; /* Remove padding on small screens */
      text-align: center; /* Center text for better layout */
    }
  }
  