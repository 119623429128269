/* General footer styling */
footer {
    background-color: #1D8996;
    color: white;
    padding: 0px 0;
}

footer .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

footer .col-md-3, footer .col-md-2, footer .col-md-4 {
    margin-top: 20px;
}

footer .col-md-3 h6, footer .col-md-2 h6, footer .col-md-4 h6 {
    margin-bottom: 15px;
    font-weight: bold;
    text-transform: uppercase;
}

/* Ensure Mayukh column takes full width on mobile */
@media (max-width: 768px) {
    footer .col-md-3 {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 0;
    }

    /* Ensure Products, Useful Links, and Contact columns stay side by side on mobile */
    footer .col-md-2, footer .col-md-4, footer .col-md-3 {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        text-align: left;
    }

    /* Flexbox settings for inner row */
    .inner-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
    }

    .inner-row > div {
        flex: 1;
        max-width: 100%;
    }
    .text-white{
        font-size: 12px;
    }
    .logo-footer{
        height: 110px !important;
        width: 120px !important;
    }
    .logo-text{
        margin-bottom: 0px !important;
        font-family: 'Baskerville';
    }
    .text-18{
        font-size: 16px;
        text-decoration: none;
        color: '#FBF8F4';
        font-family: 'Baskerville';
    }
}

/* Align columns side by side on larger screens */
@media (min-width: 769px) {
    .inner-row {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .inner-row > div {
        flex: 1;
        max-width: 25%; /* Adjust this value as needed to fit your design */
    }

    /* Ensure Mayukh column is inline with others */
    footer .col-md-3 {
        flex: 1;
        max-width: 25%;
    }
}

.d-flex {
    display: flex;
  }
  
  .align-items-center {
    align-items: center;
  }
  
  .mr-3 {
    margin-right: 15px; /* Adjust margin as needed */
  }
  

