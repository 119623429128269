.underline {
    border-bottom: 2px solid #D89F4B;
    width: 50px; 
    margin-top: 15px; 
  }

  .form-check{
    font-size: 20px !important;
  }

  .form-check-input{
    text-align: left !important;
  }

  .top-img{
    height: "200px";
  }

  @media (max-width: 767px) { /* Adjust for mobile screens */
    .top-img{
      height: "140px";
    }
  }