.img-fixed-size {
    width: 220px; /* Set your desired width */
    height: 220px; /* Set your desired height */
  }
  @media (max-width: 767px) { /* Adjust for mobile screens */
    .img-fixed-size {
      width: 170px; /* Decreased width for mobile */
      height: 170px; /* Decreased height for mobile */
    }
  }
  @media (max-width: 705px) { /* Adjust for mobile screens */
    .img-fixed-size {
      width: 150px; /* Decreased width for mobile */
      height: 150px; /* Decreased height for mobile */
    }
    .text-warning{
      font-size: 10px;
    }
    .text-success{
      font-size: 8px;
    }
  }
.price{
    font-weight: 600;
    font-size: 14px;
   
}
