@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

nav {
  display: flex;
  height: 100px;
  width: 100%;
  background: #1D8996;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px 0 100px;
  flex-wrap: wrap;
  position: relative;
  z-index: 999; /* Ensure nav is above other content */
}

nav .logo {
  background-image: url('Logo-main.jpg'); /* Path relative to the public folder */
  width: 100px; /* Set the width as needed */
  height: 90px; /* Set the height as needed */
  background-size: cover;
  background-position: center;
}

nav ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

nav ul li {
  margin: 0 5px;
}

nav ul li a,
nav ul li button {
  color: #FCFCCA;
  text-decoration: none;
  font-size: 22px;
  font-family: Times, sans-serif;
  font-weight: 500;
  padding: 8px 35px;
  border-radius: 5px;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  background: none;
  border: none;
  cursor: pointer;
}

nav ul li a.active,
nav ul li a:hover,
nav ul li button.active,
nav ul li button:hover {
  color: #111;
  background: #fff;
}

nav .menu-btn i {
  color: #fff;
  font-size: 22px;
  cursor: pointer;
  display: none;
}

input[type="checkbox"] {
  display: none;
}

@media (max-width: 920px) {
  nav .menu-btn i {
    display: block;
  }

  #click:checked ~ .menu-btn i:before {
    content: "\f00d";
  }

  nav ul {
    position: fixed;
    top: 100px; /* Adjust top to be below the navbar */
    left: -100%;
    background: #1D8996;
    height: calc(100vh - 100px); /* Adjust height to account for the navbar */
    width: 100%;
    text-align: left;
    display: block;
    transition: all 0.3s ease;
    z-index: 9; /* Ensure it is above content but below nav */
  }

  #click:checked ~ ul {
    left: 0;
  }

  nav ul li {
    width: 100%;
    margin: 40px 0;
  }

  nav ul li a,
  nav ul li button {
    width: 100%;
    margin-left: -100%;
    display: block;
    font-size: 20px;
    transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    text-align: left;
  }

  #click:checked ~ ul li a,
  #click:checked ~ ul li button {
    margin-left: 0px;
  }

  nav ul li a.active,
  nav ul li a:hover,
  nav ul li button.active,
  nav ul li button:hover {
    background: none;
    color: cyan;
  }
}

.content {
  position: relative; /* Change to relative to be below nav */
  z-index: 1;
  width: 100%;
  padding: 0 30px;
  color: #1b1b1b;
}

.content div {
  font-size: 40px;
  font-weight: 700;
}


