.loading-screen {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #1D8996;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    overflow: hidden;
  }
  
  .loading-logo {
    background-image: url('../Header/Logo-main.jpg');
    width: 200px;
    height: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    animation: dimming 1s infinite alternate;
  }
  
  @keyframes dimming {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.5;
    }
  }
  
  .split-screen {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 50%;
    background: #1D8996;
    z-index: 1000;
  }
  
  .split-screen.left {
    left: 0;
  }
  
  .split-screen.right {
    right: 0;
  }
  
  .split-screen.slide-left {
    animation: slideLeft 2s forwards;
  }
  
  .split-screen.slide-right {
    animation: slideRight 2s forwards;
  }
  
  @keyframes slideLeft {
    to {
      transform: translateX(-100%);
    }
  }
  
  @keyframes slideRight {
    to {
      transform: translateX(100%);
    }
  }
  
  
  