.gender-image {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.info-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid lightgrey;
    padding: 10px;
}

.gender-text {
    margin: 0;
    font-family: nunito_sansbold;
    letter-spacing: .0313rem;
    font-size: 1rem;
    color: black;
}

.explore-more {
    margin: 0;
    font-size: 0.875rem;
    color: grey;
    text-decoration: none;
}

.explore-more span {
    color: grey;
}

.explore-more figure {
    margin: 0;
}

.explore-more img {
    display: inline;
}

a{
    text-decoration: none;
}

/* DIVIDERS */
.divider-section {
    text-align: center;
    margin: 40px 0; /* Adjust the top and bottom margin as needed */
}

.divider-heading {
    margin: 0px;
    font-size: 24px; /* Adjust the font size as needed */
    color: #333; /* Adjust the color as needed */
}

.divider-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.responsive-divider {
    display: block; /* Ensures no extra space below the SVG */
    max-width: 100%; /* Ensures the SVG scales with the container */
    height: auto; /* Maintains aspect ratio */
    margin: 0; /* Removes default margin */
}

.text-below-head{
    font-size: 12px !important;
}

@media (max-width: 767px) {

.explore-More{
    font-size: 10px;
}

.region{
    padding: 5px;
}

}