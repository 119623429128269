  /* automated scrollbar */
  .horizontal-scroll-container {
    height: 215px;
    display: flex;
    overflow-y: hidden; /* Ensure container does not scroll */
    white-space: nowrap; /* Prevent items from wrapping */
    position: relative; /* Ensure child elements position correctly */
    scrollbar-width: none;
  }
  
  .horizontal-scroll-container .product-card {
    display: inline-block; /* Ensure products are displayed in a row */
  }
  
  .horizontal-scroll-container .product-card:not(:last-child) {
    margin-right: 20px; /* Adjust spacing between products */
  }

  .horizontal-scroll-container {
    overflow-x: auto;
    scroll-behavior: smooth;
    white-space: nowrap;
  }
  
  .product-card-wrapper {
    display: flex;
  }
  
  .product-card {
    flex: 0 0 auto; /* Ensures the product cards do not shrink */
  }
  
  
  /* Auto scroll animation */
  @keyframes autoScroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-70%); /* Adjust scroll distance here */
    }
  }
  
  /* Apply animation to products inside the container */
  .horizontal-scroll-container .product-card-wrapper {
    animation: autoScroll 20s linear infinite; /* Auto scroll animation */
  }
  
  /* Pause animation when user scrolls */
  .horizontal-scroll-container.scroll-paused .product-card-wrapper {
    animation-play-state: paused;
  }
  
  @media (min-width: 1007px) {
    
    @keyframes autoScroll {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-60%); /* Adjust scroll distance here */
        }
      }
  }
  